import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import 'vant/lib/index.css';
import 'windi.css'
import './assets/main.css'
import { NavBar, Button, Form, Field, CellGroup } from 'vant';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(NavBar).use(Button).use(Form).use(Field).use(CellGroup);

import router from './router'

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/home',
		// name: 'Home',
		component: HomeView,
		children: [
			{
				path: '/',
				name: 'Welcome',
				meta: {
					title: '欢迎'
				},
				component: () => import("../views/welcome.vue"),
			},
			{
				path: '/tools',
				name: 'Tools',
				meta: {
					title: '工具'
				},
				component: () => import("../views/tools.vue"),
				children: [
					{
						path: '/tools',
						name: 'ToolsIndex',
						meta: {
							title: '工具'
						},
						component: () => import("../views/tools/index.vue"),
					},
					{
						path: '/members',
						name: 'Members',
						meta: {
							title: '会员管理'
						},
						component: () => import("../views/tools/members.vue"),
					},
					{
						path: 'add',
						name: 'Add',
						meta: {
							title: '新增工具'
						},
						component: () => import("../views/tools/add.vue"),
					},
					{
						path: 'groupDetail',
						name: 'GroupDetail',
						meta: {
							title: '分组详情'
						},
						component: () => import("../views/tools/groupDetail.vue"),
					},
					{
						path: 'groupList',
						name: 'GroupList',
						meta: {
							title: '分组详情'
						},
						component: () => import("../views/tools/groupList.vue"),
					},
				]
			},
			{
				path: '/password',
				name: 'Password',
				meta: {
					title: '修改密码'
				},
				component: () => import("../views/password.vue"),
			},
		]
	},
	{
		// path: '/login',
		path: '/',
		name: 'login',
		component: LoginView
	},
	{
		path: '/ShareGroupDetail',
		name: 'ShareGroupDetail',
		meta: {
			title: '分组详情'
		},
		component: () => import("../views/share/shareGroupDetail.vue"),
	},
	{
		path: '/ShareGroupList',
		name: 'ShareGroupList',
		meta: {
			title: '分组详情'
		},
		component: () => import("../views/share/shareGroupList.vue"),
	},
]

const router = new VueRouter({
	mode: 'history',
	routes // (缩写) 相当于 routes: routes
})

export default router

<template>
  <div id="app">
	 <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
thead tr th{
  background-color:#f2f2f2 !important;
}
.my-dialog .el-dialog__header{
  background-color: #f8f8f8;
  padding: 10px 10px 10px;
}
.my-dialog .el-dialog__title{
  font-size: 14px;
}
.my-dialog .el-dialog__headerbtn{
  top: 10px;
  right: 10px;
}
.el-button{
  border-radius: 2px;
}
</style>
